<template>
  <b-card class="blog-edit-wrapper">
    <div class="text-center">
      <h4 class="mt-5">
        Update Price
        <feather-icon icon="Edit2Icon" />
      </h4>

    </div>

    <!-- form -->
    <validation-observer ref="addCoursePricevalidate">
      <b-form
        class="mt-2"
        style="width: 100%"
        @submit.prevent="save"
      >
        <b-row>

          <b-col
            v-if="addCoursePrice.long_course"
            md="3"
          >
            <b-form-group
              label="Category"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <b-form-input
                v-model="addCoursePrice.long_course.category"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="addCoursePrice.course"
            md="3"
          >
            <b-form-group
              label="Diploma"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <b-form-input
                v-model="addCoursePrice.long_course.name"
                readonly
              />

            </b-form-group>
          </b-col>

          <b-col
            v-if="addCoursePrice.city"
            md="3"
          >
            <b-form-group
              label="City"
              label-for="blog-edit-category"
              class="mb-2"
            >
              <b-form-input
                v-model="addCoursePrice.city.name"
                readonly
              />

            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group
              label="Price"
              label-for="blog-edit-slug"
              class="mb-2"
            >

              <validation-provider
                #default="{ errors }"
                name="price"
                rules="required"
              >
                <b-form-input
                  id="blog-edit-slug"
                  v-model="addCoursePrice.price"
                  type="number"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            class="mt-10"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-1"
              @click="save"
            >
              Save Changes
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="$router.go(-1)"
            >
              Back
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardText,
  BMediaAside,
  BMediaBody,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BCardTitle,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import { quillEditor } from 'vue-quill-editor'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'

import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@core/utils/utils'
import Vue from 'vue'
import store from '@/store'
// Import Quill and define custom size style
import Quill from 'quill'
const SizeStyle = Quill.import('attributors/style/size')
SizeStyle.whitelist = ['10px', '12px', '14px', '16px', '18px', '20px', '24px', '30px', '32px', '36px', '48px', '60px', '72px', '96px']
Quill.register(SizeStyle, true)

// Create a custom Image format
const BlockEmbed = Quill.import('blots/block/embed')
class ImageFormat extends BlockEmbed {
  static create(value) {
    let node = super.create(value)
    node.setAttribute('src', value.src)
    node.setAttribute('alt', value.alt || '')
    node.setAttribute('width', value.width || '')
    node.setAttribute('height', value.height || '')
    node.setAttribute('class', value.class || '')
    return node
  }

  static value(node) {
    return {
      src: node.getAttribute('src'),
      alt: node.getAttribute('alt'),
      width: node.getAttribute('width'),
      height: node.getAttribute('height'),
      class: node.getAttribute('class'),
    }
  }
}
ImageFormat.blotName = 'image'
ImageFormat.tagName = 'img'
Quill.register(ImageFormat, true)

export default {
  setup() {
    // const store = useStore();
    const refInputEl = ref(null)
    const refInputE2 = ref(null)
    const refPreviewEl = ref(null)
    const refPreviewE2 = ref(null)
    const Tabimage = ref('')
    const categoriesList = ref([])
    const coursesList = ref([])
    const citiesList = ref([])
    const { route } = useRouter()
    const { id } = route.value.params
    const addCoursePrice = ref({})
    store.dispatch('longPrice/GetPrice', { id }).then(response => {
      addCoursePrice.value=response?.data.data
      store.commit('longPrice/GET_PRICE', response?.data.data)
    })

    const CoursePrice = computed(() => store.state.price.Price)
    //  console.log("CoursePrice",CoursePrice)
    // const addCoursePrice = reactive(CoursePrice)

    store.dispatch('courses/getAllCoursesClassical')
      .then(response => {
        coursesList.value = response.data.data
      })
    store.dispatch('cities/getAllcitites')
      .then(response => {
        citiesList.value = response.data.data
      })

    store.dispatch('categories/AllCategory')
      .then(response => {
        console.log('response', response.data.data)
        categoriesList.value = response.data.data
      })

    const addCoursePricevalidate = ref()

    const save = () => {
      console.log(addCoursePrice)
      const formData = new FormData()

      addCoursePricevalidate.value.validate().then(success => {
        if (success) {
          formData.append('long_course_id', addCoursePrice.value.long_course.id)
          formData.append('price', addCoursePrice.value.price)
          formData.append('_method', 'put')
          if (addCoursePrice.value.city) {
            formData.append('city_id', addCoursePrice.value.city.id)
          }

          if (addCoursePrice.value.online == 1) {
            formData.append('online', 1)
          } else {
            formData.append('online', 0)
          }

          // formData.append("long_course_id", addCoursePrice.value.long_course_id);
          // formData.append("city_id", addCoursePrice.value.city_id);
          // // formData.append("online",0);

          // formData.append("category_id", addCoursePrice.value.category_id);
          console.log('formData', formData)
          store.dispatch('longPrice/UpdatePrice', { id, formData })
            .then(response => {
              Vue.swal({
                title: 'Price Updated ',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }).catch(error => {
              Vue.swal({
                title: '',
                text: `${error.response.data.message}`,
                icon: 'error',
                confirmButtonText: 'ok',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })

          console.log('alt_header_image', addCoursePrice)
        }
      })
    }
    return {

      Tabimage,
      addCoursePrice,

      addCoursePricevalidate,
      categoriesList,
      coursesList,
      citiesList,
      CoursePrice,
      id,

      save,
      required,
      email,
    }
  },

  components: {
    BCard,
    BMedia,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    BMediaAside,
    BMediaBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    quillEditor,
    BCardTitle,
  },

  directives: {
    Ripple,
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
@import "@core/scss/vue/pages/page-blog.scss";
</style>
